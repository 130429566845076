import React from "react";
import PropTypes from 'prop-types';
import {
    Button,
    Badge,
    CardHeader,
    Card,
    CardBody,
    Col,
    Container,
    DatePicker,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Fa,
    Input,
    InputFile,
    Modal,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane,
    Timeline,
    TimelineStep,
    ToastContainer,
    toast,
    Tooltip,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import NoteService from "../Security/NoteService/noteService";
import './patientView.css';
import classnames from "classnames";

export default class PatientView extends React.Component {
    constructor(props) {
        super(props);

        let id = props.match.params.id,
            isLoaded = false,
            order = {};

        if (props.location.state != null) {
            order = props.location.state.order;
            isLoaded = true;
        } else {
            this.retrieveOrder(id);
        }

        this.state = {
            id: id,
            notes: [],
            tasks: [],
            order: order,
            eOrder: JSON.parse(JSON.stringify(order)),
            isLoaded: isLoaded,
            noteModalOpen: false,
            timelineModalOpen: false,
            settingsModalOpen: false,
            noteText: "",
            completed: false,
            createdBy: {},
            createdAt: "",
            insuranceTypes: [],
            insuranceSubTypes: [],
            followUp: false,
            activeItem: "1",
            unAuthorized: false,
            loadError: false,
            verificationCompleted: false,
            originalReason: order.orderStatusReason,
            signatureReason:null,
        };

        this.retrieveNotes(id);
        this.formatStatus();
    }


    getSignatureReason(){
        const {statusReasons} = this.context;

        let reason = statusReasons.find((r) => {
            return r.description === "Dr. Signature Requested";
        });

        this.setState({signatureReason: reason});
        return reason;
    }

    static contextTypes = {
        currentTheme: PropTypes.object,
        currentUser: PropTypes.object,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        allTherapists: PropTypes.array,
        statusReasons: PropTypes.any,
    };

    formatTimelineStatus(timelineEntry) {
        const {statusReasons} = this.context;

        if(timelineEntry.reason){
            return statusReasons.get(timelineEntry.reason);
        }
        return null;
    }

    handleChange = (property, event) => {
        //update the property that the user has edited in the order...BC
        const nOrder = this.state.eOrder;
        nOrder[property] = event.target.value;
        this.setState({order: nOrder});
    };

    togglePriority() {
        let eOrder = this.state.eOrder;

        if (this.state.eOrder.priority === 0) {
            eOrder.priority = 1;
        } else {
            eOrder.priority = 0;
        }
        this.setState({
            eOrder: eOrder
        });
    }

    toggleVerification(val){
        let r = this.state.signatureReason,
            eOrder = this.state.eOrder;

        if(r === null){
            r = this.getSignatureReason();
        }

        //switch the verification state...BC
        this.state.verificationCompleted = !this.state.verificationCompleted;

        if(this.state.verificationCompleted){
            eOrder.orderStatusReason = {id:r.id, name: r.description }
        }
        else{
            eOrder.orderStatusReason = this.state.originalReason;
            //set reason to null/original reason
        }

        this.setState({
            eOrder: eOrder
        });
    }

    toggleSmsMessages() {
        let eOrder = this.state.eOrder;

        eOrder.smsMessages = !eOrder.smsMessages;

        this.setState({
            eOrder: eOrder
        });

        this.saveOrder();
    }

    toggleFollowUp() {
        this.setState({
            followUp: !this.state.followUp
        })
    }

    noteChange = (property, event) => {
        this.setState({[property]: event.target.value});
    };

    completedChange = (event) => {
        this.setState({completed: event.target.checked})
    };

    changeEmergencyContact(type) {
        let eOrder = this.state.eOrder;

        eOrder.emergencyRelationship = type;

        this.setState({
            eOrder: eOrder
        });
    }

    changeTherapist(therapist) {
        let eOrder = this.state.eOrder;

        eOrder.therapist = {
            id: therapist.id,
            name: therapist.username
        };

        this.setState({eOrder: eOrder});
    }

    changeInsuranceType(type, secondary) {
        let eOrder = this.state.eOrder;
        if (secondary === true) {
            eOrder.secondaryInsuranceType = type;
            eOrder.secondaryInsuranceSubType = null;
        } else {
            eOrder.insuranceType = type;
            eOrder.insuranceSubType = null;
        }
        this.setState({eOrder: eOrder});
    }

    changeInsuranceSubType(type, secondary) {
        if (type != null) {
            delete type.deleted;
            delete type.deletedAt;
            delete type.deletedById;
        }
        let eOrder = this.state.eOrder;
        if (secondary === true) {
            eOrder.secondaryInsuranceSubType = type;
        } else {
            eOrder.insuranceSubType = type;
        }
        this.setState({eOrder: eOrder});
    }

    retrieveNotes(id) {
        NoteService.getTherapistNotes(id).then(notes => {
            if (notes === null || notes.length === 0) {
                //we have no notes, return...BC
                return;
            }
            //copy the notes array...BC
            let con = notes.slice();

            //sort the notes...BC
            con.sort((a, b) => {
                if (a.createdAt > b.createdAt) {
                    return -1;
                } else {
                    return 1;
                }

            });

            //set the state for notes...BC
            this.setState({notes: con});
            this.triggerTaskPopup();
        })
    }

    addNewNote() {
        //get the current user context that can be passed into the note DTO...BC
        //another comment...BC
        const {currentUser} = this.context;
        let cb = {id: currentUser.id, name: currentUser.username},
            currentNotes = this.state.notes;

        let note = {
            text: this.state.noteText,
            createdAt: new Date(),
            createdBy: cb,
            assignedTo: this.state.eOrder.owner,
            completed: this.state.completed,
            orderId: this.state.order.id,
            patientName: this.state.order.patientName,
        };

        return NoteService.createTherapistNote(note).then(res => {
            this.toggleNoteModalPopup();
            currentNotes.unshift(res);
            this.setState({
                notes: currentNotes,
                completed: false,
                noteText: ""
            });
        }).catch(err => {
            //Maybe send an error to the user?...BC
        })
    }

    updateNote(note) {
        let notes = this.state.notes;
        note.completed = true;
        return NoteService.updateTherapistNote(note).then(res => {
            this.setState({notes: notes});
            //success, don't need to do anything...BC
        }).catch(err => {
            //maybe throw an error if it fails...BC
        })
    }

    retrieveOrder(id) {
        return OrderService.getActivity(id).then(data => {
            this.setState({
                order: data,
                eOrder: JSON.parse(JSON.stringify(data)),
                isLoaded: true
            });
        }).catch(err => {
            if(err === '403 Forbidden'){
                this.setState({
                    isLoaded: true,
                    unAuthorized: true
                })
            }
            else{
                this.setState({
                    isLoaded: true,
                    loadError: true
                })
            }
        })
    }

    saveOrder() {
        const {currentUser} = this.context;

        let t = this,
            st = this.state;

        st.eOrder.lastUpdatedOn = new Date();
        st.eOrder.type = 'Order';
        st.eOrder.lastUpdatedBy = {
            id: currentUser.id,
            name: currentUser.username
        };


        return OrderService.updateOrder(t.state.eOrder).then(data => {
            this.setState({
                order: data,
                eOrder: JSON.parse(JSON.stringify(data))
            });

            toast.success("Saved Successfully!");
        })
            .catch(err => {
                toast.error('Oh no! An error occurred while saving.')
            })
    }

    formatDate(date) {
        return (new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date))
    }

    formatStatus() {
        let o = this.state.eOrder;

        if (o.status === 'ReadyToDeliver') {
            return 'Waiting for Delivery'
        } else if (o.status === 'InProcess') {
            if (o.orderStatusReason && o.orderStatusReason.name === 'Verification') {
                return 'Intake / Insurance Verification';
            } else {
                return 'Insurance Review'
            }
        } else if (o.status === 'New') {
            return 'Medical Documents';
        } else {
            return o.status;
        }
    }

    fileInputHandler(files) {
        return OrderService.uploadDocument(this.state.eOrder, files).then(res => {
            //should display a popup showing upload successful...BC
        });
    };

    triggerTaskPopup() {
        let tasks = 0;
        this.state.notes.forEach(note => {
            if (note.completed === false) {
                tasks++;
            }
        });

        if (tasks > 0) {
            let message = 'You have ' + tasks + ' task(s) to complete for this patient.';
            toast.warn(message)
        }
    }

    toggleNoteModalPopup() {
        let currentState = this.state.noteModalOpen;
        this.setState({noteModalOpen: !currentState});
    }

    toggleTimelineModalPopup() {
        let newState = !this.state.timelineModalOpen,
            order = this.state.order;

        //We don't want to call the endpoint if the modal is being closed...BC
        if (newState === false) {
            this.setState({
                timelineModalOpen: newState
            });
        } else {
            OrderService.getOrderTimeline(order).then(records => {

                if(records){
                    records = records.sort((a,b) => {
                        return a > b ? 1 : -1;
                    })
                }

                this.setState({
                    timelineModalOpen: newState,
                    timelineModalOrder: order,
                    timelineModalEntries: records
                });
            });
        }
    }

    toggle = (tab) => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div className={'dbLoadingSpinner'}>
                    <Spinner multicolor/>
                </div>
            </Container>
        )
    }

    renderUnAuthorized(){
        return (
            <Container className="mt-5">
                <h3>You don't have permission to view this order</h3>
            </Container>
        )
    }

    renderLoadError(){
        return (
            <Container className="mt-5">
                <h3>We are having trouble retrieving this order.  Logout/Login or try again later.</h3>
            </Container>
        )
    }

    renderNoteModal() {
        return (
            <Container>
                <Row>
                    <Modal
                        centered
                        className="form-elegant"
                        isOpen={this.state.noteModalOpen}
                        toggle={() => this.toggleNoteModalPopup()}>
                        <ModalBody className="mx-3 pvNoteModal">
                            <CardHeader color={'red darken-1'} className="form-header text-center">
                                Add New Note
                            </CardHeader>
                            <form onSubmit={(e)=> {e.preventDefault()}} className=" mx-3 grey-text">
                                <Input label="Note Text"
                                       value={this.state.noteText}
                                       onChange={this.noteChange.bind(this, 'noteText')}
                                />
                                <Tooltip
                                    placement="right"
                                    tag="div"
                                    tooltipContent="If this is a note, check the 'completed' box. If it is a task, leave it unchecked.">
                                    <Input
                                        onChange={this.completedChange.bind(this)}
                                        filled
                                        label="Complete"
                                        type="checkbox"
                                        id="checkbox6"
                                    />
                                </Tooltip>

                                <Button color={'blue darken-1'} onClick={this.addNewNote.bind(this)}>Add Note</Button>
                            </form>
                        </ModalBody>
                    </Modal>
                </Row>
            </Container>
        )
    }

    renderTopCard() {
        let color = "blue-grey darken-2";

        return (
            <CardHeader className={'text-center patientNameMargin'} color={color}>
                <h3>
                    {this.state.order.patientName}
                    {/*<Tooltip*/}
                        {/*placement="right"*/}
                        {/*tooltipContent={'Save'}>*/}
                        {/*<i onClick={this.saveOrder.bind(this)} className="patientHeaderRear fa fa-save"> </i>*/}
                    {/*</Tooltip>*/}


                    {/*<Tooltip*/}
                        {/*placement="left"*/}
                        {/*tooltipContent={'Timeline'}>*/}
                        <div  style={{textAlign: 'center'}} className="patientHeaderFront">
                            <Row>
                                <Col size={12}>
                                    <i onClick={() => this.toggleTimelineModalPopup()}
                                       className="fa fa-clock-o"/>
                                </Col>

                                <Col size={12}>
                                    <h6>
                                    Timeline
                                    </h6>
                                </Col>
                            </Row>
                        </div>
                    {/*</Tooltip>*/}

                </h3>
            </CardHeader>
        )
    }

    renderVerificationButton(){
        let item = this.state.order;
        if(item.status === 'New'){
            if(!item.orderStatusReason || item.orderStatusReason.name !== 'Dr. Signature Requested'){
                return(
                    <Col md={'3'}>
                        <Input
                            label={this.state.verificationCompleted ? 'Verification complete' : 'Verification not complete'}
                            checked={this.state.verificationCompleted}
                            onChange={this.toggleVerification.bind(this)}
                            type="checkbox"
                            id="verificationCompleted"
                        />
                    </Col>
                )
            }
        }
        return (<div> </div>);
    }

    renderInformationTab() {
        let item = this.state.eOrder,
            t = this;

        return (
            <div>
                <Row>
                    <Col md="1"></Col>

                    <Col md="3">
                        <Input
                            value={item.address || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'address')}
                            label="Address"
                        />
                    </Col>

                    <Col md="3">
                        <Input
                            value={item.city || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'city')}
                            label="City"
                        />
                    </Col>

                    <Col md="2">
                        <Input
                            value={item.state || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'state')}
                            label="State"
                        />
                    </Col>

                    <Col md="2">
                        <Input
                            value={item.zipCode || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'zipCode')}
                            label="Zip"
                        />
                    </Col>
                </Row>


                <Row>
                    <Col md='1'>
                    </Col>

                    <Col md='2'>
                        <div className={'form-group datePickerOptions'}>
                            <DatePicker
                                format='MM-DD-YYYY'
                                disabled={true}
                                keyboard
                                value={this.state.startDate != null ? new Date(this.state.startDate) : null}
                                getValue={(evt) => this.setState({startDate: evt})}
                                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                label="Date of Birth"/>
                        </div>
                    </Col>

                    <Col md='2'>
                        <Input
                            value={item.phone || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'phone')}
                            label="Phone"
                        />
                    </Col>

                    <Col md='3'>
                        <Input
                            value={item.email || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'email')}
                            label="Email"
                        />
                    </Col>

                    <Col md='4'>
                        <Input
                            value="Classic checkbox"
                            label={this.state.eOrder.smsMessages === true ? 'This patient wants to receive SMS messages' : 'This patient does not want to receive SMS messages'}
                            checked={this.state.eOrder.smsMessages}
                            onChange={this.toggleSmsMessages.bind(this)}
                            type="checkbox"
                            id="sms"
                        />
                    </Col>

                </Row>

                <Col md="12" >
                    <hr color={'grey'}/>
                </Col>

                <Row>
                    <Col md='1'>
                    </Col>

                    <Col md="3">
                        <Input
                            value={item.emergencyContact || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'emergencyContact')}
                            label="Primary Contact (name)"
                        />

                    </Col>

                    <Col md='3'>
                        <Input
                            value={item.emergencyContactPhone || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'emergencyContactPhone')}
                            label="Contact Phone"
                        />
                    </Col>

                    <Col md='3'>
                        <div className={'pvRelationshipDropdown'}>
                            <Dropdown dropup>
                                <DropdownToggle disabled={true} caret color="blue-grey">
                                    {item.emergencyRelationship || 'Contact Relationship'}
                                </DropdownToggle>
                                <DropdownMenu basic>
                                    <DropdownItem disabled>Contact Relationship</DropdownItem>
                                    <DropdownItem divider/>
                                    <DropdownItem
                                        onClick={this.changeEmergencyContact.bind(this, "Spouse/Partner")}>Spouse/Partner</DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeEmergencyContact.bind(this, "Sibling")}>Sibling</DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeEmergencyContact.bind(this, "Child")}>Child</DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeEmergencyContact.bind(this, "Parent")}>Parent</DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeEmergencyContact.bind(this, "Aunt/Uncle")}>Aunt/Uncle</DropdownItem>
                                    <DropdownItem
                                        onClick={this.changeEmergencyContact.bind(this, "Other")}>Other</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>


                {/*<Col md="12">*/}
                    {/*<hr color={'grey'}/>*/}
                {/*</Col>*/}

                {/*<Row>*/}
                    {/*<Col md="1">*/}

                    {/*</Col>*/}

                    {/*<Col md='3'>*/}
                        {/*{renderTherapists(item)}*/}
                    {/*</Col>*/}

                    {/*<Col md={'3'}>*/}
                        {/*<Input*/}
                            {/*label={this.state.eOrder.priority === 1 ? 'This patient is priority' : 'This patient is not priority'}*/}
                            {/*checked={this.state.eOrder.priority === 1}*/}
                            {/*onChange={this.togglePriority.bind(this)}*/}
                            {/*type="checkbox"*/}
                            {/*id="priority"*/}
                        {/*/>*/}
                    {/*</Col>*/}
                    {/*{this.renderVerificationButton()}*/}
                {/*</Row>*/}


            </div>
        )
    }

    renderEquipmentTab() {
        let item = this.state.order;

        return (<div>
                {item.products.map((prod, idx) => {
                    return (
                        <Row key={idx} className={"equipmentText"}>
                            <Col md='9' >
                                <h5>
                                    {prod.productType.name} &nbsp;
                                    {prod.productSubType ? prod.productSubType.name : ""}
                                </h5>
                            </Col>
                            <Col size={'3'}>
                                <h5>
                                    Quantity: {prod.qty || 1}
                                </h5>
                            </Col>
                        </Row>
                    )
                })}
            </div>
        )
    }

    renderPrimaryInsuranceCard(theme) {
        let item = this.state.eOrder;
        const {insuranceTypes, insuranceSubTypes} = this.context;
        return (
            <div>
                <h2 className='text-center'>
                    <Badge color={'blue-grey darken-1'}>Primary Insurance</Badge>
                </h2>

                <Row>
                    <Col md='3' className={'pvRelationshipDropdown'}>
                        <Dropdown>
                            <DropdownToggle disabled caret color="blue-grey">
                                {item.insuranceType ? item.insuranceType.name : 'Payor Source'}
                            </DropdownToggle>
                            <DropdownMenu basic>
                                <DropdownItem disabled>Payor Source </DropdownItem>
                                <DropdownItem divider/>
                                {insuranceTypes.map((type, index) => {
                                    return (
                                        <DropdownItem key={index}
                                                      onClick={this.changeInsuranceType.bind(this, type, false)}>{type.name} </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>

                    <Col md='3' className={'pvRelationshipDropdown'}>
                        <Dropdown>
                            <DropdownToggle disabled caret color="blue-grey">
                                {item.insuranceSubType ? item.insuranceSubType.name : 'Insurance'}
                            </DropdownToggle>
                            <DropdownMenu basic>
                                <DropdownItem disabled>Insurance</DropdownItem>
                                <DropdownItem divider/>
                                {insuranceSubTypes.map((type, index) => {
                                    let eOrder = this.state.eOrder;
                                    if (eOrder.insuranceType && (type.insuranceType.id === eOrder.insuranceType.id)) {
                                        return (
                                            <DropdownItem key={index}
                                                          onClick={this.changeInsuranceSubType.bind(this, type, false)}>{type.name} </DropdownItem>
                                        )
                                    }
                                    return (<div key={index}></div>)
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>


                    <Col className={'infoCol'} md='3'>
                        <Input
                            value={item.insurancePolicy || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'insurancePolicy')}
                            label="Policy Number"
                        />

                    </Col>


                    <Col md='3'>
                        <Input
                            value={item.insuranceGroup || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'insuranceGroup')}
                            label="Group Number"
                        />
                    </Col>

                </Row>

            </div>
        )
    }

    renderSecondaryInsuranceCard(theme) {
        let item = this.state.eOrder;
        const {insuranceTypes, insuranceSubTypes} = this.context;
        return (
            <div>
                <h2 className='text-center'>
                    <Badge color={'blue-grey darken-1'}>Secondary Insurance</Badge>
                </h2>

                <Row>
                    <Col md='3' className={'pvRelationshipDropdown'}>
                        <Dropdown dropup>
                            <DropdownToggle disabled caret color="blue-grey">
                                {item.secondaryInsuranceType ? item.secondaryInsuranceType.name : 'Payor Source'}
                            </DropdownToggle>
                            <DropdownMenu basic>
                                <DropdownItem disabled>Secondary Payor Source </DropdownItem>
                                <DropdownItem divider/>
                                {insuranceTypes.map((type, index) => {
                                    return (
                                        <DropdownItem key={index}
                                                      onClick={this.changeInsuranceType.bind(this, type, true)}>{type.name} </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>

                    <Col md='3' className={'pvRelationshipDropdown'}>
                        <Dropdown dropup>
                            <DropdownToggle disabled caret color="blue-grey">
                                {item.secondaryInsuranceSubType ? item.secondaryInsuranceSubType.name : 'Insurance'}
                            </DropdownToggle>
                            <DropdownMenu basic>
                                <DropdownItem disabled>Secondary Insurance</DropdownItem>
                                <DropdownItem divider/>
                                {insuranceSubTypes.map((type, index) => {
                                    let eOrder = this.state.eOrder;
                                    if (eOrder.secondaryInsuranceType && (type.insuranceType.id === eOrder.secondaryInsuranceType.id)) {
                                        return (
                                            <DropdownItem key={index}
                                                          onClick={this.changeInsuranceSubType.bind(this, type, true)}>{type.name} </DropdownItem>
                                        )
                                    }
                                    return (<div key={index}></div>)
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>

                    <Col md='3'>
                        <Input
                            value={item.secondaryInsurancePolicy || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'secondaryInsurancePolicy')}
                            label="Policy Number"
                        />
                    </Col>

                    <Col md='3'>
                        <Input
                            value={item.secondaryInsuranceGroup || ''}
                            disabled={true}
                            onChange={this.handleChange.bind(this, 'secondaryInsuranceGroup')}
                            label="Group Number"
                        />
                    </Col>
                </Row>

            </div>
        )
    }

    renderInsuranceTab() {
        return (
            <Row>
                <Col md='12'>
                    {this.renderPrimaryInsuranceCard()}
                </Col>

                <Col md='12'>
                    {this.renderSecondaryInsuranceCard()}
                </Col>
            </Row>
        )
    }

    generateNote(note, index) {
        let t = this;

        function renderCompletebutton(note) {
            if (note.completed === false) {
                return (
                    <Button className={'pvButtonFloat'}
                        color={'red darken-2'}
                            onClick={() => t.updateNote(note)}
                            size={'sm'}>
                        Complete
                    </Button>
                )
            }
        }

        return (
            <div className={'pvNoteContainer'}
                 key={index}>
                <Card>
                    <CardHeader color={'blue-grey darken-2'}>
                        {t.formatDate(note.createdAt)}
                    </CardHeader>
                    <CardBody className={'pvNoteText'}>
                        {note.text}
                        <br/>
                        {renderCompletebutton(note)}
                    </CardBody>
                </Card>
                <br/>
            </div>
        )
    }

    renderNotesTab() {
        let allNotes = this.state.notes,
            notesOnly = [],
            tasksOnly = [];

        allNotes.forEach(n => {
            if(n.completed === false){
                tasksOnly.push(n);
            }
            else{
                notesOnly.push(n);
            }
        });

        return (
            <div>
                {this.renderNoteModal()}
                <div className={'pvNotesTabButton'}>
                    <Button className={'red darken-2'} onClick={() => this.toggleNoteModalPopup()}>Add Note / Task </Button>
                </div>

                <Col size={12}>
                    {'Tasks: ' + tasksOnly.length }
                    <hr/>
                </Col>

                <div className={'pvNotesGroupOuterContainer'}>
                    <div className={'pvNotesGroupInnerContainer'}>
                        {tasksOnly.map((note, idx) => {
                            return (
                                this.generateNote(note, idx)
                            )
                        })}
                    </div>
                </div>

                <Col size={12}>
                    {'Notes: ' + notesOnly.length }
                    <hr/>
                </Col>

                <div className={'pvNotesGroupOuterContainer'}>
                    <div className={'pvNotesGroupInnerContainer'}>
                        {notesOnly.map((note, idx) => {
                            return (
                                this.generateNote(note, idx)
                            )
                        })}
                    </div>
                </div>

            </div>
        )
    }

    renderAttachmentsTab() {
        const {currentUser} = this.context;
        let attachments = this.state.order.files;

        if(attachments) {
            attachments = attachments.filter((b) => {
                return b.uploadedBy ? b.uploadedBy.id == currentUser.id : false;
            });

            attachments = attachments.sort((a, b) => {
                return b.uploadedAt - a.uploadedAt;
            });
        }



        return (
            <div>
                <CardHeader color={'blue-grey darken-1'} className="text-center">
                    <InputFile btnColor={'red darken-2'} name='file' getValue={this.fileInputHandler.bind(this)}/>
                </CardHeader>
                <br/>
                <div className={'pvNotesGroupOuterContainer'}>
                    <div className={'pvNotesGroupInnerContainer'}>
                        {attachments.map((att, idx) => {
                            return (
                                this.generateAttachment(att, idx)
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    generateAttachment(att, index) {
        return (
            <div className={'pvNoteContainer'} key={index}>
                <Card>
                    <CardHeader color={'blue-grey lighten-1'}>
                        {this.formatDate(att.uploadedAt)}
                    </CardHeader>
                    <CardBody>
                        {att.filename}
                        <br/>
                        <Button
                            className={'pvButtonFloat'}
                            color={'red darken-2'}
                            size={'sm'}
                            onClick={() => this.fileDownloadHandler(att)}>
                            Download
                        </Button>
                    </CardBody>
                </Card>
                <br/>
            </div>
        )
    }

    fileDownloadHandler(attachment){
        return OrderService.downloadDocument(attachment.id).then(res => {

            let file = new File([res], attachment.filename);
            let url = window.URL.createObjectURL(file);
            let temp = document.createElement('a');

            temp.href = url;
            temp.setAttribute('download', attachment.filename);
            temp.click();
            window.URL.revokeObjectURL(url);

        })
    }

    generateTimelineEntry() {
        if (this.state.timelineModalEntries == null) {
            return;
        }

        let small = [];

        this.state.timelineModalEntries.forEach(entry => {
            let stat = this.formatTimelineStatus(entry);

            if(stat !== null){
                entry.status = stat;
                small.push(entry);
            }
        });

        return (
            small.map((att, idx) => {
                let v = true;

                if (idx === 0 || (idx % 2 === 0)) {
                    v = false;
                }

                return (
                    <TimelineStep color="red darken-4" href='#void' inverted={v} key={idx} icon="calendar-check-o">
                        <h3>{att.status}</h3>
                        <hr/>
                        <h6>{this.formatDate(att.updatedOn)}</h6>
                    </TimelineStep>
                )
            })
        )
    }

    renderOrderTimeline(theme) {
        return (
            <Timeline className="TimelineStep">
                {this.generateTimelineEntry(theme)}
            </Timeline>)
    }

    renderTimelineModal(theme) {
        return (
            <div>
                <Modal
                    isOpen={this.state.timelineModalOpen}
                    toggle={() => this.toggleTimelineModalPopup()}
                    fullHeight
                    position="left"
                >
                    <CardHeader color={'blue-grey'}>
                        <h5>
                            {this.formatStatus()} --
                            Updated: {this.formatDate(this.state.order.lastUpdatedOn || new Date())}
                        </h5>
                    </CardHeader>
                    <ModalBody>
                        {this.renderOrderTimeline(theme)}
                    </ModalBody>
                </Modal>
            </div>

        )
    }

    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        else if(this.state.unAuthorized === true){
            return this.renderUnAuthorized();
        }
        else if(this.state.loadError === true){
            return this.renderLoadError();
        }

        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />
                {this.renderTimelineModal()}

                <Container className="mt-5" style={{marginBottom: '1rem'}}>
                    <Row>
                        <Col md="12" className="mb-12">
                            {this.renderTopCard()}
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12" className="md-4">
                            <Nav tabs className="nav-justified" color={'blue-grey darken-2'}>
                                <NavItem>
                                    <NavLink to="#" className={classnames({active: this.state.activeItem === "1"})}
                                             onClick={(e) => {
                                                 e.preventDefault();
                                                 this.toggle("1");
                                             }} role="tab">
                                        <Fa icon="user" size="2x"/>
                                        <br/>
                                        Patient Details
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink to="#" className={classnames({active: this.state.activeItem === "2"})}
                                             onClick={(e) => {
                                                 e.preventDefault();
                                                 this.toggle("2");
                                             }} role="tab">
                                        <Fa icon="medkit" size="2x"/>
                                        <br/>
                                        Insurance
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink to="#" className={classnames({active: this.state.activeItem === "3"})}
                                             onClick={(e) => {
                                                 e.preventDefault();
                                                 this.toggle("3");
                                             }} role="tab">
                                        <Fa icon="wheelchair-alt" size="2x"/>
                                        <br/>
                                        Equipment
                                    </NavLink>
                                </NavItem>

                                {/*<NavItem>*/}
                                    {/*<NavLink to="#" className={classnames({active: this.state.activeItem === "4"})}*/}
                                             {/*onClick={() => {*/}
                                                 {/*this.toggle("4");*/}
                                             {/*}} role="tab">*/}
                                        {/*<Fa icon="sticky-note-o" size="2x"/>*/}
                                        {/*<br/>*/}
                                        {/*Notes / Tasks*/}
                                    {/*</NavLink>*/}
                                {/*</NavItem>*/}

                                {/*<NavItem>*/}
                                    {/*<NavLink to="#" className={classnames({active: this.state.activeItem === "5"})}*/}
                                             {/*onClick={() => {*/}
                                                 {/*this.toggle("5");*/}
                                             {/*}} role="tab">*/}
                                        {/*<Fa icon="file-text-o" size="2x"/>*/}
                                        {/*<br/>*/}
                                        {/*Attachments*/}
                                    {/*</NavLink>*/}
                                {/*</NavItem>*/}

                            </Nav>

                            <TabContent className="card" activeItem={this.state.activeItem}>
                                <TabPane tabId="1" role="tabpanel">
                                    {this.renderInformationTab()}
                                </TabPane>

                                <TabPane tabId="2" role="tabpanel">
                                    {this.renderInsuranceTab()}
                                </TabPane>

                                <TabPane tabId="3" role="tabpanel">
                                    {this.renderEquipmentTab()}
                                </TabPane>

                                {/*<TabPane tabId="4" role="tabpanel">*/}
                                    {/*{this.renderNotesTab()}*/}
                                {/*</TabPane>*/}

                                {/*<TabPane tabId="5" role="tabpanel">*/}
                                    {/*{this.renderAttachmentsTab()}*/}
                                {/*</TabPane>*/}
                            </TabContent>
                        </Col>
                    </Row>
                </Container>

            </div>


        )
    }

}