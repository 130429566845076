import React from "react";
import PropTypes from 'prop-types';
import {Route} from "react-router-dom";
import {
    Button,
    Container,
    Fa,
    ToastContainer,
    FormInline,
    CardHeader,
    Card,
    CardBody,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalBody,
    Timeline,
    TimelineStep,
    Row,
    Col,
    Input,
    Spinner
} from "mdbreact";
import './home.css';
import OrderService from "../Security/OrderService/orderService";
import CardList from "./cardList";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            sortedOrders: [],
            isLoaded: false,
            timelineModalOpen: false,
            timelineModalOrder: {},
            timelineModalEntries: [],
            typeRadio: 'allRadio',
            userContextLoaded: props.userContextLoaded,
            intakeOrders: 0,
            medicalDocuments:0,
            signatureRequested:0,
            insuranceReview:0,
            deliveryOrders:0,
            allOrders:0,
        };
    }

    static contextTypes = {
        ordersLoaded: PropTypes.bool,
        accountOrders: PropTypes.array,
        currentAccount: PropTypes.object,
        statusReasons: PropTypes.any,
    };

    componentDidMount() {
        this.retrieveOrders();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.userContextLoaded !== this.state.userContextLoaded){
            this.setState({userContextLoaded: nextProps.userContextLoaded});
        }
    }

    retrieveOrders() {
        const {currentAccount} = this.context;

        let params = {
                page: 0,
                size: 250,
                statuses: ['New', 'InProcess', 'ReadyToDeliver']
            };

        return OrderService.getOrdersByAccount(currentAccount.id, params).then(res => {
            this.setState({
                orders: res.content,
                sortedOrders: res.content,
                isLoaded: true
            });

            this.countOrders(res.content);
            this.populate();

        }).catch(err => {
            //handle error...BC
        })
    }

    formatDate(date) {
        return (new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date))
    }

    formatStatus(order) {

        if (order.status === 'ReadyToDeliver') {
            return 'Waiting for Delivery'
        } else if (order.status === 'InProcess') {
            if (order.orderStatusReason && order.orderStatusReason.name === 'Verification') {
                return 'Intake / Insurance Verification';
            } else {
                return 'Insurance Review'
            }
        } else if (order.status === 'New') {
            return 'Medical Documents';
        } else {
            return order.status;
        }
    }

    formatTimelineStatus(timelineEntry) {
        const {statusReasons} = this.context;


        if(timelineEntry.reason){
            return statusReasons.get(timelineEntry.reason);
        }
        return null;
    }

    populate(type) {
        let so = this.state.orders.slice();

        switch (type) {
            case 'intakeRadio': {
                so = so.filter((o) => {
                    return (o.status === 'InProcess' && (o.orderStatusReason && o.orderStatusReason.name === 'Verification'));
                });
                break;
            }
            case 'medDocRadio': {
                so = so.filter((o) => {
                    return (o.status === 'New');
                });
                break;
            }

            case 'reviewRadio': {
                so = so.filter((o) => {
                    return (o.status === 'InProcess');
                });
                break;
            }
            case 'deliveryRadio': {
                so = so.filter((o) => {
                    return (o.status === 'ReadyToDeliver');
                });
                break;
            }

            case 'allRadio':
            default: {
                //don't need to do anything.  leave orders as they are.
            }

        }

        this.setState({
            sortedOrders: so,
            typeRadio: type || 'allRadio',

        });
    }

    toggleTimelineModalPopup(currentOrder) {
        let newState = !this.state.timelineModalOpen;

        //We don't want to call the endpoint if the modal is being closed...BC
        if (newState === false) {
            this.setState({
                timelineModalOpen: newState
            });
        } else {
            OrderService.getOrderTimeline(currentOrder).then(records => {

                if(records){
                    records = records.sort((a,b) => {
                        return a > b ? 1 : -1;
                    })
                }

                this.setState({
                    timelineModalOpen: newState,
                    timelineModalOrder: currentOrder,
                    timelineModalEntries: records
                });
            });
        }
    }

    generateTimelineEntry() {
        if (this.state.timelineModalEntries == null) {
            return;
        }

        let small = [];

        this.state.timelineModalEntries.forEach(entry => {
            let stat = this.formatTimelineStatus(entry);

            if(stat !== null){
                entry.status = stat;
                small.push(entry);
            }
        });

        return (
           small.map((att, idx) => {
                let v = true;

                if (idx === 0 || (idx % 2 === 0)) {
                    v = false;
                }

                return (
                    <TimelineStep color="red darken-4" href='#void' inverted={v} key={idx} icon="calendar-check-o">
                        <h4>{att.status}</h4>
                        <hr/>
                        <h6>{this.formatDate(att.updatedOn)}</h6>
                    </TimelineStep>
                )
            })
        )
    }

    renderTimelineModal() {
        let order = this.state.timelineModalOrder;
        return (
            <div>
                <Modal
                    isOpen={this.state.timelineModalOpen}
                    toggle={() => this.toggleTimelineModalPopup(order)}
                    fullHeight
                    position="left"
                >
                    <CardHeader color={'blue-grey'}>
                        <h4>
                            {this.formatStatus(order)}
                        </h4>
                        <br/>
                        Updated: {this.formatDate(order.lastUpdatedOn || new Date())}
                    </CardHeader>
                    <ModalBody className={'homeTimelineModalBody'}>
                        {this.renderOrderTimeline()}
                    </ModalBody>
                </Modal>
            </div>

        )
    }

    renderOrderTimeline() {
        return (
            <Timeline className="TimelineStep">
                {this.generateTimelineEntry()}
            </Timeline>)
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div className={'dbLoadingSpinner'}>
                    <Spinner multicolor/>
                </div>
            </Container>
        )
    }

    countOrders(orders){
        let intakeOrders = 0,
            medDoc = 0,
            sigReq = 0,
            insRev = 0,
            delivery = 0;

        orders.forEach(order => {
            switch (order.status){
                case 'New':
                    medDoc++;
                    break;
                case 'InProcess':
                    if(order.orderStatusReason && order.orderStatusReason.name === 'Verification'){
                        intakeOrders++;
                    }
                    else{
                        insRev++;
                    }
                    break;
                case 'ReadyToDeliver':
                    delivery++;
                    break;
            }

        });

        this.setState({
            intakeOrders: intakeOrders,
            medicalDocuments:medDoc,
            signatureRequested:sigReq,
            insuranceReview:insRev,
            deliveryOrders:delivery,
            allOrders:intakeOrders + medDoc + sigReq + insRev + delivery,
        });
    }


    renderStatusCards() {
        return (
            <Row style={{justifyContent:'space-around'}}>
                <Col size={2}>
                    <Button
                        size={'lg'}
                        color={'red'}
                        onClick={() => this.populate("allRadio")}
                        disabled={this.state.typeRadio === "allRadio"}
                    >
                        All Orders
                        <br/>
                        {this.state.allOrders}
                    </Button>
                </Col>
                <Col size={2}>
                    <Button
                        size={'lg'}
                        color={'red'}
                        onClick={() => this.populate("intakeRadio")}
                        disabled={this.state.typeRadio === "intakeRadio"}
                    >
                        Intake / Verification
                        <br/>
                        {this.state.intakeOrders}
                    </Button>
                </Col>
                <Col size={2}>
                    <Button
                        size={'lg'}
                        color={'red'}
                        onClick={() => this.populate("medDocRadio")}
                        disabled={this.state.typeRadio === "medDocRadio"}
                    >
                        Medical Documents
                        <br/>
                        {this.state.medicalDocuments}
                    </Button>
                </Col>
                <Col size={2}>
                    <Button
                        size={'lg'}
                        color={'red'}
                        onClick={() => this.populate("reviewRadio")}
                        disabled={this.state.typeRadio === "reviewRadio"}
                    >
                        Insurance Review
                        <br/>
                        {this.state.insuranceReview}
                    </Button>
                </Col>
                <Col size={2}>
                    <Button
                        size={'lg'}
                        color={'red'}
                        onClick={() => this.populate("deliveryRadio")}
                        disabled={this.state.typeRadio === "deliveryRadio"}
                    >
                        Waiting For Delivery
                        <br/>
                        {this.state.deliveryOrders}
                    </Button>
                </Col>

                <Col size={12}>
                    <hr/>
                </Col>
            </Row>
        )
    }

    render() {
        if (this.state.isLoaded === false || this.state.userContextLoaded === false) {
            return this.renderLoadingSpinner();
        }

        return (
            <div className={'dbMainContainer'}>
                {this.renderTimelineModal()}
                <div align="center">
                    <ToastContainer
                        hideProgressBar={false}
                        newestOnTop={true}
                        autoClose={5000}
                    />
                    <div style={{marginLeft: '4rem', marginRight: '4rem'}}>
                        {this.renderStatusCards()}
                    </div>

                    <CardList orders={this.state.orders}
                              toggleTimelineFunction={this.toggleTimelineModalPopup.bind(this)}
                              sortedOrders={this.state.sortedOrders}/>
                </div>
            </div>)
    }

}

export default Home;

