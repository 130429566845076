import React from "react";
import {Route, Switch} from "react-router-dom";

import Login from './Seating/Login/login';
import PatientView from './Seating/Patient/patientView';
import Home from './Seating/Home/home';
import PatientList from './Seating/Patient/patientList';


class Routes extends React.Component {
    constructor(props){
        super(props);


        this.state={
            loggedIn: props.loggedIn,
            userContextLoaded: props.userContextLoaded
        };

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.userContextLoaded !== this.state.userContextLoaded){
            this.setState({userContextLoaded: nextProps.userContextLoaded});
        }
    }

    render() {
        return (
            <Switch>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/" component={Login}/>
                <Route exact path="/orders" component={(props) => <Home {...props } userContextLoaded={this.state.loggedIn} /> }/>
                <Route exact path="/order/:id" component={(props) => <PatientView {...props } userContextLoaded={this.state.loggedIn} /> }/>
                <Route exact path="/patientList" component={(props) => <PatientList {...props } userContextLoaded={this.state.loggedIn} /> }/>

                <Route
                    render={function () {
                        return <h1>Not Found</h1>;
                    }}
                />
            </Switch>
        );
    }
}

export default Routes;
