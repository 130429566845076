import React from "react";
import PropTypes from 'prop-types';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DatePicker,
    DataTable,
    Fa,
    Input,
    FormInline,
    Modal,
    ModalBody,
    Spinner,
    Timeline,
    TimelineStep
} from "mdbreact";
import OrderService from '../Security/OrderService/orderService';


export default class patientList extends React.Component {
    constructor(props){
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Patient Name",
                    field: "name",
                    sort: "asc",
                    width: 200
                },
                {
                    label: "Date of Birth",
                    field: "dateOfBirth",
                    sort: "asc",
                    width: 270
                },
                {
                    label: "Phone Number",
                    field: "phone",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Order Type",
                    field: "orderType",
                    sort: "asc",
                    width: 270
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 200
                },
                {
                    label: "Setup Date",
                    field: "setupDate",
                    sort: "asc",
                    width: 200
                },
                {
                    label:"Timeline",
                    field:"Timeline",
                    sort:"asc",
                    width:100
                }
            ],
            rows: [
            ]
        };

        this.state={
            data: data,
            isLoaded: false,
            timelineModalOpen: false,
            timelineModalOrder:{},
            timelineModalEntries:[],
            dateOfBirth:"",
            patientName: '',
            userContextLoaded: props.userContextLoaded,
        };
    }

    static contextTypes = {
        currentAccount: PropTypes.object,
        currentUser: PropTypes.object,
        statusReasons: PropTypes.any
    };

    componentDidMount() {
        this.retrieveOrders();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.userContextLoaded !== this.state.userContextLoaded){
            this.setState({userContextLoaded: nextProps.userContextLoaded});
        }
    }

    formatDate(date) {
        return (new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date))
    }

    formatDateSmall(date){
        if(date){
            return new Date(date).toLocaleDateString();
        }
        return "No Date On File"
    }

    formatStatus(order){
        if(order.status === 'ReadyToDeliver'){
            return 'Waiting for Delivery'
        }
        else if(order.status === 'InProcess'){
            if(order.orderStatusReason && order.orderStatusReason.name === 'Verification'){
                return 'Intake / Insurance Verification';
            }
            else{
                return 'Insurance Review';
            }
        }
        else if(order.status === 'New'){
            return 'Medical Documents';
        }
        else{
            return order.status;
        }
    }

    formatTimelineStatus(timelineEntry){
        const {statusReasons} = this.context;

        if(timelineEntry.reason){
            return statusReasons.get(timelineEntry.reason);
        }
        return null;
    }

    retrieveOrders(){
        const {currentAccount} = this.context;

        let params = {
                page: 0,
                size: 250,
            };

        return OrderService.getOrdersByAccount(currentAccount.id, params).then(res => {
            this.sortRecords(res);

        }).catch(err => {
            //handle error...BC
        })
    }

    searchAccountOrders(name, date){
        const {currentAccount} = this.context;

        this.setState({isLoaded: false});

        let params = {
            page:0,
            pageSize: 250
        };

        if(name !== ""){
            params.patientName = name;
        }

        if(date !== ""){
            params.dob = date.toLocaleDateString();
        }

        return OrderService.getOrdersByAccountPNDOB(currentAccount.id, params).then(res => {
            this.sortRecords(res);
        }).catch(err => {
            //handle error...BC
        })
    }

    sortRecords(records){
        let ary = [],
            dt = this.state.data;

        if(records == null){
            this.setState({
                data: dt,
                isLoaded: true
            });
            return;
        }

        try {


            records.content.forEach((order) => {
                ary.push({
                    id: order.id,
                    name: order.patientName,
                    dateOfBirth: order.dateOfBirth,
                    phone: order.phone,
                    orderType: order.orderType,
                    status: this.formatStatus(order),
                    setupDate: this.formatDateSmall(order.setupDate),
                    button: this.renderTimelineButton(order)
                });
            });

        }
        catch (e){
            var cat = e;
        }

        dt.rows = ary;

        this.setState({
            data: dt,
            isLoaded: true
        });
    }

    toggleTimelineModalPopup(currentOrder) {
        let newState = !this.state.timelineModalOpen;


        //We don't want to call the endpoint if the modal is being closed...BC
        if(newState === false){
            this.setState({
                timelineModalOpen: newState
            });
        }
        else{
            OrderService.getOrderTimeline(currentOrder).then(records => {
                if(records){
                    records = records.sort((a,b) => {
                        return a > b ? 1 : -1;
                    })
                }

                this.setState({
                    timelineModalOpen: newState,
                    timelineModalOrder: currentOrder,
                    timelineModalEntries: records
                });
            });
        }
    }

    generateTimelineEntry() {
        if(this.state.timelineModalEntries == null){
            return;
        }

        let small = [];

        this.state.timelineModalEntries.forEach(entry => {
            let stat = this.formatTimelineStatus(entry);

            if(stat !== null){
                entry.status = stat;
                small.push(entry);
            }
        });

        return (
            small.map((att, idx) => {
                let v = true;

                if (idx === 0 || (idx % 2 === 0)) {
                    v = false;
                }

                return (
                    <TimelineStep color="red darken-4" href='#void' inverted={v} key={idx} icon="calendar-check-o">
                        <h4>{att.status}</h4>
                        <hr/>
                        <h6>{this.formatDate(att.updatedOn)}</h6>
                    </TimelineStep>
                )
            })
        )
    }

    handleDatePickerChange = (value) => {
        //update the date property on the object that the user has edited in the order...BC
        this.setState({dateOfBirth: value});
    };

    renderSearchSection(){
        return(
            <div >
                <FormInline>
                    <Col md={'4'}>
                        <div className={'patientListName'}>
                            <Input label={'Patient Name'}
                                   value={this.state.patientName}
                                   onChange={(e) =>  this.setState({patientName: e.target.value})}/>
                        </div>
                    </Col>

                    <Col md={'4'} >
                        <div className={'patientListDate'}>
                        <DatePicker
                            format='MM-DD-YYYY'
                            placeholder={'No Date'}
                            valueDefault={'No Date'}
                            invalidDateMessage={''}
                            keyboard
                            value={this.state.dateOfBirth || ''}
                            getValue={ (evt) => this.handleDatePickerChange(evt)}
                            onInputChange={ (evt) => this.setState({dateOfBirth: evt.target.value})}
                            mask={[/\d/, /\d/,'-', /\d/, /\d/,'-', /\d/, /\d/ , /\d/, /\d/]}
                            label="Date of Birth" />
                        </div>
                    </Col>

                    <Col md={'4'}>
                        <Button color={'red darken-2'}
                            onClick={()=> this.searchAccountOrders(this.state.patientName, this.state.dateOfBirth)}
                            size={'md'}>
                            Search
                        </Button>
                    </Col>

                </FormInline>
            </div>
        )
    }

    renderTimelineButton(order){
        return(
            <Fa icon={'clock-o'} size={"lg"} onClick={() => this.toggleTimelineModalPopup(order)}> </Fa>
        )
    }

    renderTable(){
        if(this.state.isLoaded === true){
            return(
                <DataTable
                    searching={false}
                    entriesOptions={[25,50]}
                    info={false}
                    striped
                    small
                    data={this.state.data}> </DataTable>
            )
        }
        else{
            return(<div> </div>);
        }

    }

    renderLoadingSpinner(){
        return(
            <Container className="mt-5">
                <div style={{textAlign: 'center', verticalAlign: 'center' }}>
                    <Spinner multicolor />
                </div>
            </Container>
        )
    }

    renderTimelineModal(theme) {
        let order = this.state.timelineModalOrder;
        return (
            <div>
                <Modal
                    isOpen={this.state.timelineModalOpen}
                    toggle={() => this.toggleTimelineModalPopup(order)}
                    fullHeight
                    position="left"
                >
                    <CardHeader color={'blue-grey'}>
                        <h4>
                            {this.formatStatus(order)}
                        </h4>
                        <br/>
                        Updated: {this.formatDate(order.lastUpdatedOn || new Date())}
                    </CardHeader>
                    <ModalBody style={{backgroundColor: 'transparent'}}>
                        {this.renderOrderTimeline(order,theme)}
                    </ModalBody>
                </Modal>
            </div>

        )
    }

    renderOrderTimeline(order,theme) {
        return (
            <Timeline className="TimelineStep">
                {this.generateTimelineEntry(order,theme)}
            </Timeline>)
    }


    render(){
        if(this.state.isLoaded === false || this.state.userContextLoaded === false){
            return(this.renderLoadingSpinner());
        }

        return(
            <Container className="mt-5">
                {this.renderTimelineModal()}
                <Badge color={'blue-grey lighten-1'}>
                {this.renderSearchSection()}
                </Badge>
                <Card>
                    <CardBody>
                        {this.renderTable()}
                    </CardBody>
                </Card>
            </Container>
        )
    }
}